import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  isUpdatingStatus: false,
  error: null,
  total: 0,
  limit: 5,
  list: [],
  currentedit: {}
};

const orderReducer = (state = initialState, action: DispatchAction) => {
  switch (action.type) {
  case Actions.CREATE_ORDER_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.CREATE_ORDER_SUCCESS: {
    return {
      ...state,
      isLoading: false
    };
  }
  case Actions.CREATE_ORDER_FAILURE: {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  case Actions.GET_ORDER_LIST_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.GET_ORDER_LIST_SUCCESS: {
    return {
      ...state,
      total: action.payload.data.length,
      list: [...action.payload.data],
      isLoading: false
    };
  }
  case Actions.GET_ORDER_LIST_FAILURE: {
    return {
      ...state,
      isLoading: false,
      list: [],
      error: action.error
    };
  }

  case Actions.GET_ORDER_DETAIL_REQUEST: {
    return {
      ...state,
      isLoading: true,
      currentedit: {},
    };
  }
  case Actions.GET_ORDER_DETAIL_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      currentedit: {...action.payload.data}
    };
  }
  case Actions.GET_ORDER_DETAIL_FAILURE: {
    return {
      ...state,
      isLoading: false,
      currentedit: {},
      error: action.error
    };
  }

  case Actions.UPDATE_ORDERSTATUS_REQUEST: {
    return {
      ...state,
      isUpdatingStatus: true,
    };
  }
  case Actions.UPDATE_ORDERSTATUS_SUCCESS: {
    return {
      ...state,
      isUpdatingStatus: false,
      list: state.list.map((record: {ID:string}) => record.ID === action.payload.data.ID ? action.payload.data : record)
    };
  }
  case Actions.UPDATE_ORDERSTATUS_FAILURE: {
    return {
      ...state,
      isUpdatingStatus: false,
    };
  }

  default: {
    if(state)
      return state;
    return initialState;
  }
  }
};

export default orderReducer;