import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  LinearProgress,
  Button
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";

import {
  DeleteForever as DeleteIcon,
  MarkEmailRead as MarkAsReadIcon,
} from "@mui/icons-material";

import { Order, NotificationsListType } from "../../types/notifications";
import { EnhancedNotificationsTableProps } from "../../types/props";

import { markNotificationAsRead, deleteNotification } from "../../redux/actions/notifications.action";
import { ReduxResponse } from "../../types/store";
import { toast } from "react-toastify";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props: EnhancedNotificationsTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof NotificationsListType) => (event: React.MouseEvent<unknown>) => onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key="index"
          align="right"
          className="whitespace-nowrap"
        >
          No
        </TableCell>
        <TableCell
          key="notificationTitle"
          align="center"
          className="whitespace-nowrap"
        >
          Title
        </TableCell>
        <TableCell
          key="notificationContent"
          align="center"
          className="whitespace-nowrap"
          style={{ whiteSpace: "normal" }}
        >
          <TableSortLabel
            active={orderBy === "notificationContent"}
            direction={orderBy === "notificationContent" ? order : "asc"}
            onClick={createSortHandler("notificationContent")}
          >
            Content
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="notificationContent"
          align="center"
          className="whitespace-nowrap"
          style={{ whiteSpace: "normal" }}
        >
          <TableSortLabel
            active={orderBy === "sentDate"}
            direction={orderBy === "sentDate" ? order : "asc"}
            onClick={createSortHandler("sentDate")}
          >
            Date
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="index"
          align="center"
          className="whitespace-nowrap"
        >
          Read / Unread
        </TableCell>
        <TableCell
          key="index"
          align="center"
          className="whitespace-nowrap"
        >
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const NotificationsListTable = ({ search }: { search: string} ) => {

  const dispatch = useDispatch();
  const { isLoading, total, list } = useSelector(({ notificationsReducer }) => notificationsReducer);
  console.log(toast);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof NotificationsListType>("ID");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof NotificationsListType) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: number = parseInt(event.target.value, 10);
    setRowsPerPage(newValue);
    setPage(0);
  };

  const visibleRows: any = React.useMemo(() =>
    stableSort(list, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    ), [order, orderBy, page, rowsPerPage, list],
  );

  const handleMarkAsRead = (notificationID:string) => {
    dispatch(markNotificationAsRead(notificationID));
  };

  const handleDelete = (notificationID:string) => {
    dispatch(deleteNotification(notificationID))
      .then((response: ReduxResponse) => {
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error));
  };

  React.useEffect(() => {

  }, [dispatch]);

  let currentNumber = page * rowsPerPage;
  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center w-full p-4">
        <h1 className="text-xl">Notifications</h1>
        {list.length > 0 && isLoading && <CachedIcon className="animate-spin" />}
      </div>

      {
        isLoading ? (
          <div className="flex justify-center w-full px-12 py-32">
            <LinearProgress className="w-96" />
          </div>
        ) : list.length > 0 ? (
          <Box className="w-full fade-up-anim anim-500">
            <Paper className="w-full mb-1">
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="orderListTable"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={list.length}
                  />
  
                  <TableBody>
                    {
                      visibleRows.map((row: NotificationsListType) => JSON.stringify(row).toLowerCase().includes(search.toLowerCase()) && (
                        <TableRow
                          key={row.ID}
                          role="checkbox"
                          tabIndex={-1}
                          className="cursor-pointer"
                          hover
                        >
                          <TableCell className="whitespace-nowrap" align="right" >{++currentNumber}</TableCell>
                          <TableCell align="center" >{row.notificationTitle}</TableCell>
                          <TableCell align="left"  >{row.notificationContent}</TableCell>
                          <TableCell className="whitespace-nowrap" align="center" >{row.sentDate}</TableCell>
                          <TableCell className="whitespace-nowrap" align="center" >{row.isSent ? "Readed" : 
                            <Button variant="contained" startIcon={<MarkAsReadIcon />} className="bg-primary" disabled={isLoading} onClick={() => handleMarkAsRead(row.ID)}>
                              <p className="ml-2">Mark as Read</p>
                            </Button>
                          }</TableCell>
                          <TableCell className="whitespace-nowrap" align="center">
                            <Button variant="contained" startIcon={<DeleteIcon />} className="bg-primary" disabled={isLoading} onClick={() => handleDelete(row.ID)}>
                              <p className="ml-2">Delete</p>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : (
          <div className="flex flex-col items-center w-full">
            <img
              alt="placeholder"
              src="/assets/images/order/list-empty.png"
              width={200}
            />

            <h1 className="mt-8 text-lg text-center">
              The notification list is empty.
            </h1>
          </div>
        )
      }

    </div>
  );
};

export default NotificationsListTable;