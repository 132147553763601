import { Dispatch } from "redux";
import * as Actions from "../constants";
import axios, { AxiosError } from "axios";

// Get Notification Email list action
export const getNotificationemailList: any = () => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.GET_NOTIFICATIONEMAIL_LIST_REQUEST });
  return axios.get(`${process.env.REACT_APP_SERVER_API}/cognitouser/emails`)
    .then(response => {
      if(response.data.success === true) {
        dispatch({
          type: Actions.GET_NOTIFICATIONEMAIL_LIST_SUCCESS,
          payload: response.data
        });
        return response.data;
      } else {
        dispatch({
          type: Actions.GET_NOTIFICATIONEMAIL_LIST_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.GET_NOTIFICATIONEMAIL_LIST_FAILURE,
        error
      });
      
      return [];
    });
};
