import React, { useState } from "react";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, InputAdornment, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import OrderListTable from "../components/order/OrderListTable";
import { placeLog } from "../redux/actions/log.action";

export default function HomePage() {
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(({ cognitouserReducer }) => cognitouserReducer);

  const [search, setSearch] = useState("");

  const ClickNewOrder = () => {
    dispatch(placeLog("Order Created"));
    navigate("/order/save");
  };

  const ClickUpdateOrder = (record:any) => {
    //dispatch(placeLog(`Order Edit - ${record.ID}`));
    navigate("/order/save", {state: {updateRecord: record}});
  };

  return (
    <div className="flex flex-col items-center w-full">
      <main className="container flex flex-col w-full py-12">
        {
          user && (
            <div className="flex justify-between w-full px-4 pb-6">
              <TextField
                variant="outlined"
                className="w-80"
                placeholder="Search items..."
                value={search}
                onChange={({ target: { value } }) => setSearch(value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              <Button
                variant="contained"
                className="px-4 py-3 bg-primary font-bold"
                onClick={ClickNewOrder}
              >
                <AddIcon />
                <p className="ml-2">new order</p>
              </Button>
            </div>
          )
        }

        <OrderListTable search={search} updateFunction={ClickUpdateOrder} />
      </main>
    </div>
  );
}
