import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, TextField } from "@mui/material";

import ButtonLoader from "../../components/shared/ButtonLoader";
import AuthBannerImage from "../../components/form/AuthBannerImage";
import { CheckIfRegisteredUserInput, CheckRegisteredUserSchema } from "../../lib/validations/user.schema";
import { checkIfVerifiedEmail } from "../../redux/actions/cognitouser.action";
import { ReduxResponse } from "../../types/store";

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const { isLoading } = useSelector(({ cognitouserReducer }) => cognitouserReducer);

  // Destructure form hooks for the input
  const methods = useForm<CheckIfRegisteredUserInput>({ resolver: zodResolver(CheckRegisteredUserSchema) });
  const { register, handleSubmit, reset, formState } = methods;
  const { errors } = formState;

  // Handle login form submit
  function onSubmit(data: CheckIfRegisteredUserInput) {
    dispatch(checkIfVerifiedEmail(data))
      .then((response: ReduxResponse) => {
        if (response.success) {
          reset();
          if(!response.result.Enabled) {
            toast.error("This user is disabled.");
          } else {
            if(response.result.UserStatus === "CONFIRMED")
              navigate("/user/signin");
            else
              navigate("/user/signup");
          }
        } else {
          navigate("/user/verify");
        }
      })
      .catch(() => toast.error("Internal Server Error!"));
  }

  return (
    <main className="flex justify-center items-center w-full h-screen">
      <div className="flex justify-center items-center w-full h-full p-4 bg-secondary">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-center items-center fade-left-anim anim-500"
        >
          <h1 className="text-4xl mb-8">SIGN IN / SIGN UP</h1>
          <TextField
            label="Username"
            variant="outlined"
            className="w-80 my-2"
            {...register("email")}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
          />

          <Button
            type="submit"
            variant="contained"
            className="w-80 h-12 my-8 bg-primary font-bold tracking-widest"
            disabled={isLoading}
          >
            {isLoading ? <ButtonLoader /> : "Next"}
          </Button>

          <div className="flex justify-center items-center my-2">
            <p>Go back to </p>
            <Link to={"/"} className="mx-2 underline hover:text-blue-500">Homepage</Link>
          </div>
        </form>
      </div>

      <AuthBannerImage />
    </main>
  );
}
