import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Button, Divider, Paper, InputAdornment } from "@mui/material";

import { Dispatch } from "redux";

import ProfileBannerImage from "../../components/form/ProfileBannerImage";

//import { formatCreditCardNumber, isValidPaymentInfo } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { TryPaymentVerification, TryPaymentDelete, CheckVerificationValue, TrySettingPrimaryPayment } from "../../redux/actions/cognitouser.action";
import { ReduxResponse } from "../../types/store";

import { 
  TextField,
  Grid
} from "@mui/material";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import AddNewCard from "../../components/card/addnew";
import ExistCard from "../../components/card/existcard";

function CardCheckForm(props:any) {
  const dispatch: Dispatch = useDispatch();
  const {paymentInfo} = props;
  const [checkValue, setCheckValue] = useState(0);
  
  const handleSubmit = () => {
    console.log("Submit", JSON.stringify(paymentInfo));
    if(!checkValue)
      return;

    dispatch(CheckVerificationValue(paymentInfo.ID, checkValue))
      .then((response: ReduxResponse) => {
        props.handleClose();
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error.message));
    
  };

  return(
    <Dialog
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>Check verification value</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please input the value of card verification and click Confirm. <br/>This confirmation will be performed soon.
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="verificationvalue"
          name="verificationvalue"
          type="number"
          fullWidth
          onChange={(e) => setCheckValue(parseFloat(e.target.value))}
          value={checkValue}
          error={!checkValue}
          helperText={!checkValue ? "Please input the checking value." : ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">$</InputAdornment>
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Verify</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function PaymentCardPage() {
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();

  const [openCardCheck, setOpenCardCheck] = useState(false);
  const [checkPaymentInfoData, setCheckPaymentInfoData] = useState(null);

  const { user, paymentlist, paymentloading } = useSelector(({ cognitouserReducer }) => cognitouserReducer);
  console.log("payment list", paymentlist);

  const ClickUpdateCardInfo = () => {
    navigate("/user/profile/cardedit");
  };

  const handleCloseCardCheck = () => {
    setOpenCardCheck(false);
  };

  const ClickVerifyCard = (paymentInfo:any) => {
    dispatch(TryPaymentVerification(paymentInfo.ID))
      .then((response: ReduxResponse) => {
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error.message));
  };

  const ClickCheckVerification = (paymentInfo:any) => {
    setOpenCardCheck(true);
    setCheckPaymentInfoData(paymentInfo);
  };  

  const ClickDeleteCard = (paymentInfo: any) => {
    dispatch(TryPaymentDelete(paymentInfo.ID))
      .then((response: ReduxResponse) => {
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error.message));
  };

  const ClickSetPrimary = (paymentInfo: any) => {
    dispatch(TrySettingPrimaryPayment(paymentInfo.paymentId))
      .then((response: ReduxResponse) => {
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error.message));
  };

  return (user && user.firstName) ? (
    <main className="flex flex-col items-center w-full">
      <ProfileBannerImage />

      <div className="container flex flex-col items-center justify-center mx-4 pb-16">
        
        <Paper className="flex flex-col w-[80%] max-w-[80%] p-8 -mt-20 z-10 fade-up-anim anim-500">
          <div className="flex flex-col items-center">
            <Avatar className="w-32 h-32 border-2 border-gray-600 bg-gray-500 text-7xl text-gray-200">{user.firstName[0]}</Avatar>
            <h1 className="m-4 text-3xl">{user.firstName} {user.middleName} {user.lastName}</h1>
          </div>
          <div className="container flex flex-col items-center justify-center py-8">
            <div className="flex-col justify-center">
              <Grid container spacing={2}>  
                {
                  paymentlist.length > 0 ? 
                    <>
                      {paymentlist.map((paymentInfo: any, index: number) => (
                        <Grid item xs={12} lg={6} xl={paymentlist.length > 1 ? 4 : 6} className="flex justify-center" key={index}>
                          <ExistCard key={index} cardInfo={paymentInfo} ClickVerifyCard={ClickVerifyCard} ClickCheckVerification={ClickCheckVerification} ClickDeleteCard={ClickDeleteCard}  setPrimaryPayment={ClickSetPrimary}  />
                        </Grid>
                      ))
                      }
                      <Grid item xs={12} lg={6} xl={paymentlist.length > 1 ? 4 : 6} className="flex justify-center">
                        <AddNewCard onClick={ClickUpdateCardInfo} disabled={paymentloading} />
                      </Grid>
                    </>
                    :
                    <Grid item xs={12} lg={6} xl={12} className="flex justify-center">
                      <AddNewCard onClick={ClickUpdateCardInfo} disabled={paymentloading} />
                    </Grid>
                }
                
              </Grid>
            </div>
          </div>

          <Divider flexItem />
        </Paper>
      </div>
      <CardCheckForm paymentInfo={checkPaymentInfoData} open={openCardCheck} handleClose={handleCloseCardCheck}/>
    </main>
  ) : <></>;
}