import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../redux/actions/cognitouser.action";

export default function LogoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(logoutUser());
    navigate("/");
  }, [dispatch]);

  return (
    <main>

    </main>
  );
}