import { OrderTableHeadCell } from "../types/order";
import { BarsTableHeadCell } from "../types/bar";
import { OrderAccountLinksTableHeadCell } from "../types/orderaccountlink";

// Month select config
export const monthDropDown: string[] = Array.from({ length: 12 }, (_: number, index: number) => String(index + 1));

// Year select config
const startYear: number = new Date().getFullYear();
export const yearDropDown: string[] = Array.from({ length: 20 }, (_: number, index: number) => String(startYear + index));

// Payment images
export const paymentImageURLs: string[] = [
  "/assets/images/payment/visa.svg",
  "/assets/images/payment/shop-pay.svg",
  "/assets/images/payment/paypal.svg",
  "/assets/images/payment/mastercard.svg",
  "/assets/images/payment/gpay.svg",
  "/assets/images/payment/apple-pay.svg",
  "/assets/images/payment/amex.svg",
];


// State infos
export const stateInfo = [
  {
    name: "Alabama",
    abbreviation: "AL"
  },
  {
    name: "Alaska",
    abbreviation: "AK"
  },
  {
    name: "American Samoa",
    abbreviation: "AS"
  },
  {
    name: "Arizona",
    abbreviation: "AZ"
  },
  {
    name: "Arkansas",
    abbreviation: "AR"
  },
  {
    name: "California",
    abbreviation: "CA"
  },
  {
    name: "Colorado",
    abbreviation: "CO"
  },
  {
    name: "Connecticut",
    abbreviation: "CT"
  },
  {
    name: "Delaware",
    abbreviation: "DE"
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC"
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM"
  },
  {
    name: "Florida",
    abbreviation: "FL"
  },
  {
    name: "Georgia",
    abbreviation: "GA"
  },
  {
    name: "Guam",
    abbreviation: "GU"
  },
  {
    name: "Hawaii",
    abbreviation: "HI"
  },
  {
    name: "Idaho",
    abbreviation: "ID"
  },
  {
    name: "Illinois",
    abbreviation: "IL"
  },
  {
    name: "Indiana",
    abbreviation: "IN"
  },
  {
    name: "Iowa",
    abbreviation: "IA"
  },
  {
    name: "Kansas",
    abbreviation: "KS"
  },
  {
    name: "Kentucky",
    abbreviation: "KY"
  },
  {
    name: "Louisiana",
    abbreviation: "LA"
  },
  {
    name: "Maine",
    abbreviation: "ME"
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH"
  },
  {
    name: "Maryland",
    abbreviation: "MD"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA"
  },
  {
    name: "Michigan",
    abbreviation: "MI"
  },
  {
    name: "Minnesota",
    abbreviation: "MN"
  },
  {
    name: "Mississippi",
    abbreviation: "MS"
  },
  {
    name: "Missouri",
    abbreviation: "MO"
  },
  {
    name: "Montana",
    abbreviation: "MT"
  },
  {
    name: "Nebraska",
    abbreviation: "NE"
  },
  {
    name: "Nevada",
    abbreviation: "NV"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ"
  },
  {
    name: "New Mexico",
    abbreviation: "NM"
  },
  {
    name: "New York",
    abbreviation: "NY"
  },
  {
    name: "North Carolina",
    abbreviation: "NC"
  },
  {
    name: "North Dakota",
    abbreviation: "ND"
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP"
  },
  {
    name: "Ohio",
    abbreviation: "OH"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK"
  },
  {
    name: "Oregon",
    abbreviation: "OR"
  },
  {
    name: "Palau",
    abbreviation: "PW"
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA"
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI"
  },
  {
    name: "South Carolina",
    abbreviation: "SC"
  },
  {
    name: "South Dakota",
    abbreviation: "SD"
  },
  {
    name: "Tennessee",
    abbreviation: "TN"
  },
  {
    name: "Texas",
    abbreviation: "TX"
  },
  {
    name: "Utah",
    abbreviation: "UT"
  },
  {
    name: "Vermont",
    abbreviation: "VT"
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI"
  },
  {
    name: "Virginia",
    abbreviation: "VA"
  },
  {
    name: "Washington",
    abbreviation: "WA"
  },
  {
    name: "West Virginia",
    abbreviation: "WV"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI"
  },
  {
    name: "Wyoming",
    abbreviation: "WY"
  }
];

export const orderType = [
  {
    label: "DWQ",
    value: "D"
  },
  {
    label: "Subpoena",
    value: "S"
  }
];
export const orderLabelByType = (type:string) => {
  const foundOrder = orderType.find(order => order.value.toUpperCase() === type.toUpperCase());
  return foundOrder ? foundOrder.label : "Unknown";
};
export const stateNameFromAbbreviation = (abbr: string) => {
  const foundState = stateInfo.find(state => state.abbreviation.toUpperCase() === abbr.toUpperCase());
  return foundState ? foundState.name : "Unknown";
};
 
export const orderTableHeadCells: readonly OrderTableHeadCell[] = [
  {
    id: "orderNumber",
    numeric: true,
    label: "Order Number",
  },
  {
    id: "type",
    numeric: true,
    label: "Document Type",
  },
  {
    id: "court",
    numeric: true,
    label: "Court of Docket",
  },
  {
    id: "causeNumber",
    numeric: true,
    label: "Cause Number",
  },
  {
    id: "plaintiffs",
    numeric: true,
    label: "Plaintiffs",
  },
  {
    id: "defendants",
    numeric: true,
    label: "Defendants",
  },
  {
    id: "orderphoneNumber",
    numeric: true,
    label: "Cell Phone Number",
  },
  {
    id: "phoneCode",
    numeric: true,
    label: "CellphoneCarrier",
  },
  {
    id: "updatedAt",
    numeric: true,
    label: "Order Update Date",
  },
  {
    id: "opStartAt",
    numeric: true,
    label: "Processing Start Date",
  },
  {
    id: "opEndAt",
    numeric: true,
    label: "Processing End Date",
  }
];
export const orderDetailHeadCells: readonly OrderTableHeadCell[] = [
  {
    id: "ID",
    numeric: false,
    label: "Order Number",
  },
  {
    id: "accountCode",
    numeric: true,
    label: "Account Code",
  },
  {
    id: "type",
    numeric: true,
    label: "Document Type",
  },
  {
    id: "court",
    numeric: true,
    label: "Court of Docket",
  },
  {
    id: "causeNumber",
    numeric: true,
    label: "Cause Number",
  },
  {
    id: "plaintiffs",
    numeric: true,
    label: "Plaintiffs",
  },
  {
    id: "defendants",
    numeric: true,
    label: "Defendants",
  },
  {
    id: "orderphoneNumber",
    numeric: true,
    label: "Cell Phone Number",
  },
  {
    id: "phoneCode",
    numeric: true,
    label: "Carrier Code Key",
  },
  {
    id: "startedAt",
    numeric: true,
    label: "Order Create Date",
  },
  {
    id: "updatedAt",
    numeric: true,
    label: "Order Update Date",
  }
];

export const barsHeadCells: readonly BarsTableHeadCell[] = [
  {
    id: "barState",
    numeric: false,
    label: "Bar State",
  },
  {
    id: "barNumber",
    numeric: false,
    label: "Bar Number",
  }
];

export const OrderAccountLinksTableHeadCells: readonly OrderAccountLinksTableHeadCell[] = [
  {
    id: "email",
    numeric: false,
    label: "EMail",
  },
  {
    id: "barID",
    numeric: false,
    label: "Bar Number",
  },
  {
    id: "partyRepresented",
    numeric: false,
    label: "Party Represented",
  },
  {
    id: "orderingParty",
    numeric: false,
    label: "Ordering Party",
  }
];

export const representedPartyCategory = [
  {
    label: "Ordering Party",
    value: "O"
  },
  {
    label: "Non-Ordering Party",
    value: "N"
  }
];