import { Dispatch } from "redux";
import * as Actions from "../constants";
import axios, { AxiosError } from "axios";
import { handleAxiosError } from "../../utils/functions";

// Create new log action
export const placeLog: any = (logText:string) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.LOG_REGISTER_REQUEST });

  return axios.post(`${process.env.REACT_APP_SERVER_API}/log`, {logtext: logText})
    .then(response => {
      dispatch({ type: Actions.LOG_REGISTER_SUCCESS });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.LOG_REGISTER_FAILURE,
        error
      });
      
      handleAxiosError(error);
    });
};