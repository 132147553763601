import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  newTotal: 0,
  limit: 5,
  list: [
  ],
};

const notificationsReducer = (state = initialState, action: DispatchAction) => {
  switch (action.type) {
  case Actions.GET_NOTIFICATIONS_LIST_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.GET_NOTIFICATIONS_LIST_SUCCESS: {
    return {
      ...state,
      total: action.payload.data.length,
      list: [...action.payload.data].sort(),
      newTotal: [...action.payload.data].filter((record: {isSent:boolean}) => record.isSent !== true)?.length,
      isLoading: false
    };
  }
  case Actions.GET_NOTIFICATIONS_LIST_FAILURE: {
    return {
      ...state,
      isLoading: false,
      list: [],
      total: 0,
      newTotal: 0,
      error: action.error
    };
  }

  case Actions.MARK_NOTIFICATION_READ_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.MARK_NOTIFICATION_READ_SUCCESS: {
    const newList = state.list?.map((record: {ID:string, isSent: boolean}) => record.ID === action.payload.data.ID ? {...record, isSent:action.payload.data.isSent} : record);
    return {
      ...state,
      isLoading: false,
      list: [...newList],
      total: newList.length,
      newTotal: newList.filter((record: {isSent:boolean}) => record.isSent !== true)?.length,
    };
  }
  case Actions.MARK_NOTIFICATION_READ_FAILURE: {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  case Actions.DELETE_NOTIFICATION_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.DELETE_NOTIFICATION_SUCCESS: {
    const newList = state.list?.filter((record: {ID:string}) => record.ID !== action.payload.data.ID );
    return {
      ...state,
      isLoading: false,
      list: [...newList],
      total: newList.length,
      newTotal: newList.filter((record: {isSent:boolean}) => record.isSent !== true)?.length,
    };
  }
  case Actions.DELETE_NOTIFICATION_FAILURE: {
    return {
      ...state,
      isLoading: false
    };
  }

  default: {
    if(state)
      return state;
    return initialState;
  }
  }
};

export default notificationsReducer;