import React from "react";
//import { useSelector } from "react-redux";

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Tooltip,
  IconButton,
  LinearProgress
} from "@mui/material";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {  
  OrderAccountLinksTableHeadCells
} from "../../config/static-data";
import { Order, OrderAccountLinksType } from "../../types/orderaccountlink";
import { EnhancedOrderAccountLinksTableProps } from "../../types/props";
import { getFieldValueFromListWithCondition } from "../../utils/functions";
import { representedPartyCategory } from "../../config/static-data";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props: EnhancedOrderAccountLinksTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof OrderAccountLinksType) => (event: React.MouseEvent<unknown>) => onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key="index"
          align="center"
          className="whitespace-nowrap"
        >
          No
        </TableCell>
        {OrderAccountLinksTableHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="whitespace-nowrap"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key="index"
          align="center"
          className="whitespace-nowrap"
        >
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const OrderAccountLinkTable = ({notificationEmailList, isLoading, updateFunction, addFunction, deleteFunction}: {notificationEmailList: any[], isLoading?:boolean, updateFunction: (row:any) => void, addFunction?: () => void, deleteFunction: (row:any) => void}) => {

  const list = [...notificationEmailList];
  const total = list.length;

  //const { list:listOfBars } = useSelector(({ barsReducer }) => barsReducer);  

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof OrderAccountLinksType>("ID");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof OrderAccountLinksType) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: number = parseInt(event.target.value, 10);
    setRowsPerPage(newValue);
    setPage(0);
  };

  const visibleRows: any = React.useMemo(() =>
    stableSort(list, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    ), [order, orderBy, page, rowsPerPage, list],
  );

  let currentNumber = page * rowsPerPage;
  return (
    <div className="flex flex-col w-75">
      {
        isLoading === true ? (
          <div className="flex justify-center w-full px-12 py-32">
            <LinearProgress className="w-96" />
          </div>
        ) : (
          <Box className="w-full fade-up-anim anim-500">
            <Paper className="w-full mb-1">
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-labelledby="orderListTable"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={list.length}
                  />

                  <TableBody>
                    {
                      visibleRows.map((row: any) => (
                        <TableRow
                          key={row.ID}
                          role="checkbox"
                          tabIndex={-1}
                          className="cursor-pointer"
                          hover
                          
                        >
                          <TableCell className="whitespace-nowrap" align="right" onClick={() => {return updateFunction(row);}}>{++currentNumber}</TableCell>
                          <TableCell className="whitespace-nowrap" align="left" onClick={() => {return updateFunction(row);}}>{ row.email }</TableCell>
                          <TableCell className="whitespace-nowrap" align="left" onClick={() => {return updateFunction(row);}}>{ row.barID }</TableCell>
                          <TableCell className="whitespace-nowrap" align="left" onClick={() => {return updateFunction(row);}}>{ getFieldValueFromListWithCondition(representedPartyCategory, "label", "value", row.partyRepresented )}</TableCell>
                          <TableCell className="whitespace-nowrap" align="left" onClick={() => {return updateFunction(row);}}>{ row.orderingParty }</TableCell>
                          <TableCell className="whitespace-nowrap" align="center" >
                            <Tooltip title="Delete" arrow>
                              <IconButton
                                
                                color="inherit"
                                size="small"
                              >
                                <DeleteTwoToneIcon fontSize="small" onClick={() => {return deleteFunction(row);}} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                    <TableRow
                      key="newAddRow"
                      role="checkbox"
                      tabIndex={-1}
                      className="cursor-pointer"
                      
                      hover
                    >
                      <TableCell className="whitespace-nowrap" colSpan={6} align="center" onClick={addFunction}>Click here to add a new item</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        )
      }
    </div>
  );
};
//<TableCell className="whitespace-nowrap" align="left" onClick={() => {return updateFunction(row);}}>{getFieldValueFromList(listOfBars, "barState", row.barID)}-{getFieldValueFromList(listOfBars, "barNumber", row.barID)}</TableCell>
export default OrderAccountLinkTable;