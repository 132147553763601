import { isValidState } from "../../utils/functions";
import { z } from "zod";
import validator from "validator";

export const RegisterUserSchema = z
  .object({
    firstName: z.string().min(1, "Required"),
    middleName: z.string(),
    lastName: z.string().min(1, "Required"),
    firmName: z.string().min(1, "Required"),
    firmDomain: z.string(),
    email: z.string().min(1, "Required").email({ message: "Email format is invalid" }),
    address1: z.string().min(1, "Required"),
    address2: z.string(),
    city: z.string().min(1, "Required"),
    state: z.string().min(1, "Required").refine(isValidState, { message: "Invalid state" }),
    zip: z.string().min(1, "Required"),
    creditNumber: z.string(),
    expireMonth: z.string(),
    expireYear: z.string(),
    creditZip: z.string(),
    creditCode: z.string(),
    creditOwner: z.string(),
    password: z
      .string().min(1, "Required")
      .max(30, "Password must be less than 30 chars")
      .regex(/[A-Z]/, "Must contain at least 1 uppercase")
      .regex(/[a-z]/, "Must contain at least 1 lowercase")
      .regex(/[0-9]/, "Must contain at least 1 number")
      .regex(/[!@#$%^&*(),.?":{}|<>]/, "Must contain at least 1 special character"),
    confirm: z.string().min(1, "Required")
  })
  .refine(data => data.password === data.confirm, {
    path: ["confirm"],
    message: "Passwords must be match"
  });

export const LoginUserSchema = z
  .object({
    email: z.string().min(1, "Required").email({ message: "Email format is invalid" }),
    password: z.string().min(1, "Required")
  });

export const CheckRegisteredUserSchema = z
  .object({
    email: z.string().min(1, "Required").email({ message: "Email format is invalid" }),
  });

export const ResetPasswordSchema = z
  .object({
    password: z
      .string().min(1, "Required")
      .max(30, "Password must be less than 30 chars")
      .regex(/[A-Z]/, "Must contain at least 1 uppercase")
      .regex(/[a-z]/, "Must contain at least 1 lowercase")
      .regex(/[0-9]/, "Must contain at least 1 number")
      .regex(/[!@#$%^&*(),.?":{}|<>]/, "Must contain at least 1 special character"),
    confirm: z.string().min(1, "Required")
  })
  .refine(data => data.password === data.confirm, {
    path: ["confirm"],
    message: "Passwords must be match"
  });


export type RegisterUserInput = z.infer<typeof RegisterUserSchema>;
export type LoginUserInput = z.infer<typeof LoginUserSchema>;
export type CheckIfRegisteredUserInput = z.infer<typeof CheckRegisteredUserSchema>;
export type ResetPasswordInput = z.infer<typeof ResetPasswordSchema>;


export const UpdateUserSchema = z
  .object({
    firstName: z.string().min(1, "Required"),
    middleName: z.string(),
    lastName: z.string().min(1, "Required"),
    firmName: z.string().min(1, "Required"),
    firmDomain: z.string(),
    address1: z.string().min(1, "Required"),
    address2: z.string(),
    city: z.string().min(1, "Required"),
    state: z.string().min(1, "Required").refine(isValidState, { message: "Invalid state" }),
    zip: z.string().min(1, "Required"),
  });
export type UpdateUserInput = z.infer<typeof UpdateUserSchema>;

const validateExpiryDate = (expiry:string) => {
  const [month, year] = expiry.split("/").map((val) => parseInt(val, 10));
  const now = new Date();
  const currentMonth = now.getMonth() + 1; // JavaScript months are 0-11
  const currentYear = now.getFullYear() % 100; // Get last two digits of the year

  if (month < 1 || month > 12) {
    return false;
  }

  if (year < currentYear || (year === currentYear && month < currentMonth)) {
    return false;
  }

  return true;
};

export const UpdateCardSchema = z
  .object({
    number: z
      .string()
      .min(14, "min length of number is 14 digits")
      .max(19, "max length of number is 19 digits")
      .refine((val) => validator.isCreditCard(val), {
        message: "Invalid credit card number",
      }),
    name: z.string().min(1, "Required"),
    expiry: z
      .string()
      .min(1, "Required")
      .refine((val) => validateExpiryDate(val), {
        message: "Invalid expiry date",
      }),
    cvc: z.string().min(3, "Required of 3 or 4 digits").max(4, "Required of 3 or 4 digits"),
    zip: z.string().min(5, "Required of 5 digits").max(5, "Required of 5 digits"),
  });
export type UpdateCardInput = z.infer<typeof UpdateCardSchema>;