import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import WalletIcon from "@mui/icons-material/Wallet";

import { NavbarConfigType } from "../types/shared";

export const authConfig: NavbarConfigType[] = [
  {
    id: 1,
    name: "Login",
    icon: LoginOutlinedIcon,
    path: "/user/login"
  },
  /*
  {
    id: 2,
    name: "Sign up",
    icon: PersonAddAltOutlinedIcon,
    path: "/user/signup"
  },
  */
  {
    id: 3,
    name: "Header button/link",
    icon: PersonAddAltOutlinedIcon,
    path: "/"
  }
];

export const userMenuConfig: NavbarConfigType[] = [
  {
    id: 1,
    name: "Account",
    icon: PersonIcon,
    path: "/user/profile"
  },
  {
    id: 11,
    name: "Payment Card",
    icon: WalletIcon,
    path: "/user/paymentcard"
  },
  {
    id: 2,
    name: "Settings",
    icon: SettingsIcon,
    path: "/user/settings"
  },
  /*
  {
    id: 3,
    name: "Bar Settings",
    icon: SettingsIcon,
    path: "/user/bars"
  },
  */
  {
    id: 4,
    name: "Logout",
    icon: LogoutIcon,
    path: "/user/logout"
  }
];