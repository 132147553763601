import React, {useState} from "react";
import { useSelector } from "react-redux";
import { Avatar, Button, Divider, Paper } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import WebsiteIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NoteAltIcon from "@mui/icons-material/NoteAlt";


import ProfileBannerImage from "../../components/form/ProfileBannerImage";
import ProfileRecord from "../../components/shared/ProfileRecord";
//import { formatCreditCardNumber, isValidPaymentInfo } from "../../utils/functions";
import { useNavigate } from "react-router-dom";


import BarListTable from "../../components/user/BarListTable";

import {
  Add as AddIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";


export default function ProfilePage() {
  const navigate = useNavigate();

  const { user, paymentlist } = useSelector(({ cognitouserReducer }) => cognitouserReducer);

  const ClickNewBar = () => {
    navigate("/user/bars/new");
  };

  const ClickUpdateProfile = () => {
    navigate("/user/profile/edit");
  };

  const [searchBar, setSearchBar] = useState("");

  console.log("paymentlist", paymentlist);

  return (user && user.firstName) ? (
    <main className="flex flex-col items-center w-full">
      <ProfileBannerImage />

      <div className="container flex flex-col items-center justify-center mx-4 pb-16">
        <Paper className="flex flex-col max-w-[600px] p-8 -mt-20 z-10 fade-up-anim anim-500">
          <div className="flex flex-col items-center">
            <Avatar className="w-32 h-32 border-2 border-gray-600 bg-gray-500 text-7xl text-gray-200">{user.firstName[0]}</Avatar>
            <h1 className="m-4 text-3xl">{user.firstName} {user.lastName}</h1>
          </div>

          <div className="my-6">
            <ProfileRecord icon={EmailIcon} content={user.email} />
            <ProfileRecord icon={BusinessIcon} content={user.firmName} />
            <ProfileRecord icon={WebsiteIcon} content={user.firmDomain} />
            <ProfileRecord icon={LocationOnIcon} content={`${user.address1 ? user.address1 : ""} ${user.address2 ? user.address2 : "" } ${user.city ? user.city : "" } ${user.state ? user.state : ""}`} />
            <ProfileRecord icon={NoteAltIcon} content={`${user.zip ? user.zip : ""}`} />
          </div>

          <div className="flex justify-center">
            <Button
              onClick={ClickUpdateProfile}
              variant="contained"
              className="w-80 h-12 my-4 bg-primary font-bold tracking-widest"
            >
              {"update profile"}
            </Button>
          </div>
          
          <Divider flexItem />

          <div className="flex m-8" />
          <div className="flex flex-col items-center">
            <h1 className="m-4 text-3xl">Bar states and numbers</h1>
            <div className="flex justify-between w-full">
              <TextField
                variant="outlined"
                className="w-50"
                placeholder="Search items..."
                value={searchBar}
                onChange={({ target: { value } }) => setSearchBar(value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />

              <Button
                variant="contained"
                className="px-4 py-3 bg-primary font-bold"
                onClick={ClickNewBar}
              >
                <AddIcon />
                <p className="ml-2">new bar</p>
              </Button>
            </div>
            <div className="w-full">
              <BarListTable search={searchBar} />
            </div>
          </div>
        </Paper>
      </div>

    </main>
  ) : <></>;
}