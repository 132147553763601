import axios, { AxiosError, AxiosRequestConfig, AxiosResponse  } from "axios";

export const downloadOrderResult = (order: any) => {
  // Define request configuration with correct typing
  const config: AxiosRequestConfig = {
    responseType: "blob", // Correct usage of responseType
  };

  return axios.get<Blob>(`${process.env.REACT_APP_SERVER_API}/buckets/orderresultfile/${order.ID}`, config)
    .then((response: AxiosResponse<Blob>) => {
      // Extract the filename from the Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      let filename = order.documentfileoriginalname; // Default filename
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch?.[1]) {
          filename = filenameMatch[1];
        }
      }
      
      console.log("Header", response.headers);
      const pdfBlob = response.data;
      const url = window.URL.createObjectURL(pdfBlob);

      // Create a temporary <a> element to trigger the download
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute(
        "download",
        filename
      ); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);

      return response.data;
    })
    .catch((error: AxiosError) => {
      console.log(error);
      return [];
      //handleAxiosError(error);
    });
};