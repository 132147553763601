import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { roleConfig } from "../config/roleconfig";

const Auth = ({ allowedRoles } : any) => {
  let { user } = useSelector(({ cognitouserReducer }) => cognitouserReducer);
  if(user === null)
    user = localStorage.getItem("currentuser");

  return allowedRoles.find((role : string) => user !== null && role === roleConfig.user.lawyer /*user.role.includes(role)*/) ? (
    <Outlet />
  ) : (
    <Navigate to="/user/login" replace/>
  );
};

export default Auth;